import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import '../../../styles/index.scss'
//Components
import { Button } from '../Button'
import Popover, { PopoverBody, PopoverFooter } from '../../Popover/Popover'
import useScreenSize from '../../../hooks/use-screen-size'
import { IconLabelConditionalDisplay } from '../../IconLabel/variants/IconLabelConditionalDisplay'
import { useParams } from 'react-router-dom'
import { useOnlineAvailabilityActions } from '../../Account/restricted/OnlineAvailability'
import { onlineAvailability } from '../../settings/RecordPropTypes'
import RecordContext from '../../../contexts/RecordContext'
import { onlineAvailabilitySchema } from '../../../schemas/input-schemas/input-schema-online-availability'
import Select from '../../form/select'
import { forwardRef } from 'react'
import ClickAwayListener from 'react-click-away-listener'
import UserContext from '../../../contexts/UserContext'
import CheckboxField from '../../form/CheckboxField'

/**
 * The Edit Button provides a popover of options for moderators to change aspects of the current record
 */
export const ButtonAdminPageOptions = ({ children, ...props }) => {
  //Context
  const { availability } = useContext(RecordContext)
  const {
    accountsAccessArray,
    displayAdminWorkbench,
    setDisplayAdminWorkbenchHandler,
  } = useContext(UserContext)

  //Hooks
  const { screenSize } = useScreenSize()

  //State
  const [active, setActive] = useState(false)
  const [buttonHeight, setButtonHeight] = useState(0)

  //Refs
  const buttonRef = useRef(null)
  const popoverRef = useRef(null)
  const optionsRef = useRef(null)

  useEffect(() => {
    if (buttonRef?.current && !buttonHeight)
      setButtonHeight(buttonRef.current?.clientHeight)
  }, [buttonRef])

  const handleBlur = (event) => {
    if (
      !event.relatedTarget &&
      !popoverRef?.current?.contains(event.target) &&
      !event.target.contains(optionsRef.current)
    ) {
      setActive(false)
    }
  }

  const handleClickAway = () => {
    if (active) {
      setActive(false)
    }
  }

  return (
    <div
      className={[
        'display-flex',
        'flex-align-center',
        'position-relative',
      ].join(' ')}
    >
      <Button
        active={active}
        data-testid="nac-objects--edit-page-button"
        iconName="locked"
        iconOnly={screenSize === 'mobile'}
        iconSize="xs"
        onClick={() => setActive(!active)}
        reduced
        ref={buttonRef}
        thin
        theme="primary-dark"
        size="2xs"
        srText={children}
      >
        {children}
      </Button>
      {active && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popover
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="width-card-lg-16"
            hide={() => setActive(false)}
            id="edit-page"
            onBlur={handleBlur}
            position={[{ right: 0, top: buttonHeight }]}
            title="Manage Options"
            ref={popoverRef}
            tabIndex={0}
          >
            <PopoverBody id="edit-page" reduced>
              <div
                className={[
                  'display-flex',
                  'flex-justify',
                  'flex-column',
                  'padding-2',
                  'padding-bottom-0',
                ].join(' ')}
              >
                <IconLabelConditionalDisplay displayTo="Moderators" />
                <AvailabilityOptions
                  ref={optionsRef}
                  availability={availability}
                />
              </div>
              {accountsAccessArray.accessGrantedToAccountAdminTools && (
                <div
                  className={[
                    'border-top-1px',
                    'border-base-lighter',
                    'display-flex',
                    'flex-gap-sm',
                    'flex-justify',
                    'flex-column',
                    'padding-2',
                  ].join(' ')}
                >
                  <IconLabelConditionalDisplay displayTo="Admins" />
                  <CheckboxField
                    checked={displayAdminWorkbench}
                    data-testid="nac-public-display-options_admin-workbench"
                    id="admin-workbench-option"
                    label="Display Admin Workbench"
                    onChange={setDisplayAdminWorkbenchHandler}
                    onKeyPress={setDisplayAdminWorkbenchHandler}
                    value="display-admin-workbench"
                  />
                </div>
              )}
            </PopoverBody>
            <PopoverFooter hide={() => setActive(false)} id="edit-page" />
          </Popover>
        </ClickAwayListener>
      )}
    </div>
  )
}

ButtonAdminPageOptions.defaultProps = {
  children: 'Manage...',
}

ButtonAdminPageOptions.propTypes = {
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
}

export const AvailabilityOptions = forwardRef(
  ({ availability, ...props }, ref) => {
    if (
      availability === 'notDigitized' ||
      availability === 'partiallyDigitized'
    )
      availability = ''
    const schema = onlineAvailabilitySchema

    //Hooks
    const { naId } = useParams()

    //State
    const [option, setOption] = useState(availability)

    const { onSubmitHandler } = useOnlineAvailabilityActions(option, naId, () =>
      window.location.reload()
    )

    const values = [
      { label: 'None', id: 'none', value: '' },
      {
        label: 'Unrestricted Only',
        id: 'unrestrictedOnly',
        value: 'unrestrictedOnly',
      },
      {
        label: 'Fully Digitized',
        id: 'fullyDigitized',
        value: 'fullyDigitized',
      },
    ]

    const onSubmit = (event) => {
      onSubmitHandler()
    }

    const changeHander = (event, value) => {
      const v = value || event?.target?.value
      const newOption = onlineAvailabilitySchema.options.filter(
        (o) => o.id === v
      )[0].value
      setOption(newOption)
    }

    useEffect(() => {
      if (option !== availability) onSubmitHandler()
    }, [option])

    return (
      <label
        className={['display-flex', ' flex-gap', 'flex-column'].join(' ')}
        htmlFor={schema.id}
      >
        <div
          className={[
            'display-flex',
            'flex-align-center',
            'flex-justify',
            'flex-row',
            'flex-gap-xs',
            'minh-button',
            'margin-bottom-neg-2',
          ].join(' ')}
        >
          <h2
            className={[
              'display-flex',
              'flex-gap',
              'flex-row',
              'font-sans-2xs',
              'margin-bottom-0',
            ].join(' ')}
          >
            {schema.label}{' '}
            {/*schema.description && (
              <Tooltip
                content={schema.description}
                delay={500}
                display="block"
                id={'#' + schema.id + 'description'}
                position="topCenter"
              >
                <Button
                  align="center"
                  iconName="circle-questions"
                  iconOnly
                  iconSize="xs"
                  reduced
                  shape="pill"
                  srText={`${schema.label} details`}
                  textOnly
                  theme="base-dark"
                />
              </Tooltip>
            )*/}
          </h2>
        </div>
        <div
          className={['display-flex', 'flex-column', 'width-full'].join(' ')}
        >
          <Select
            onChange={changeHander}
            error={null}
            ref={ref}
            schema={schema.options}
            selected={schema.options.filter((o) => o.value === option)}
            {...props}
          />
        </div>
      </label>
    )
  }
)

AvailabilityOptions.defaultProps = {}
AvailabilityOptions.propTypes = {
  /**
   * The content provided to the component.
   */
  availability: PropTypes.oneOf([
    ...onlineAvailability,
    'notDigitized',
    'partiallyDigitized',
  ]),
}
AvailabilityOptions.displayName = 'AvailabilityOptions'
